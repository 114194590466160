import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/style.css'
import '@/assets/iconfont/iconfont.css'
import "@/assets/fonts/font.css";
import axios from 'axios';
import Particles from "@tsparticles/vue2";
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.



// 配置Element UI
Vue.use(ElementUI, { locale });

// 配置Particles插件
Vue.use(Particles, {
  init: async engine => {
    // await loadFull(engine);
    await loadSlim(engine); //如需精简版请使用
  },
});


// 配置路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


// 挂载axios到Vue原型链上
Vue.prototype.axios = axios;
Vue.config.productionTip = false;


// 创建Vue实例
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // meta: {
    //   title: 'Protein Sublocalization Localization Database'
    // }
  },

  {
    path: '/update-data-SR',
    name: 'update-data-SR',
    component: () => import(/* webpackChunkName: "about" */ '@/views/UpdateDate.vue'),
    meta: {
      title: 'Update Data'
    }
  },
  {
    path: '/data/protein',
    name: 'data-protein',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataProtein.vue'),
    meta: {
      title: 'Protein Information | SubCELL'
    }
  },
  {
    path: '/data/rna',
    name: 'data-rna',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataRNA.vue'),
    meta: {
      title: 'RNA Information | SubCELL'
    }
  },
  {
    path: '/data/dna',
    name: 'data-dna',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataDNA.vue'),
    meta: {
      title: 'DNA Information | SubCELL'
    }
  },

  {
    path: '/data/compartment',
    name: 'data-compartment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataCompartment.vue'),
    meta: {
      title: 'Compartment Information | SubCELL'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ContactView.vue'),
    meta: {
      title: 'Contact US | SubCELL'
    }
  },







    // Search Protein==========================================================================

  {
    path: '/search-protein-page',
    name: 'search-protein-page',
    component: () => import(/* webpackChunkName: "about" */ '@/views/SearchProteinPage.vue'),
    meta: {
      title: 'Search for Protein | SubCELL'
    }
  },
  {
    path: '/search-protein',
    name: 'search-protein',
    component: () => import(/* webpackChunkName: "about" */ '@/views/SearchProtein.vue'),
    meta: {
      title: 'Protein Search Results | SubCELL'
    }
  },
  {
    path: '/search-protein-by-seq',
    name: 'search-protein-by-seq',
    component: () => import(/* webpackChunkName: "about" */ '@/views/SearchProteinSeq.vue'),
    meta: {
      title: 'Protein Sequence Similarity Results | SubCELL'
    }
  },

    // Search RNA==========================================================================
    
    {
      path: '/search-rna-page',
      name: 'search-rna-page',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchRnaPage.vue'),
      meta: {
        title: 'Search for RNA | SubCELL'
      }
    },
    {
      path: '/search-rna',
      name: 'search-rna',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchRna.vue'),
      meta: {
        title: 'RNA Search Results | SubCELL'
      }
    },
    {
      path: '/search-rna-by-seq',
      name: 'search-rna-by-seq',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchRnaSeq.vue'),
      meta: {
        title: 'RNA Sequence Similarity Results | SubCELL'
      }
    },
    // Search DNA==========================================================================

    {
      path: '/search-dna-page',
      name: 'search-dna-page',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDnaPage.vue'),
      meta: {
        title: 'Search for DNA | SubCELL'
      }
    },
    {
      path: '/search-dna',
      name: 'search-dna',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDna.vue'),
      meta: {
        title: 'DNA Search Results | SubCELL'
      }
    },
    // Search Compartment==========================================================================
    {
      path: '/search-compartment-page',
      name: 'search-compartment-page',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchCompartmentPage.vue'),
      meta: {
        title: 'Search for Compartment | SubCELL'
      }
    },
    {
      path: '/search-compartment',
      name: 'search-compartment',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SearchCompartment.vue'),
      meta: {
        title: 'Compartment Search Results | SubCELL'
      }
    },

    {
      path: '/download',
      name: 'download',
      component: () => import(/* webpackChunkName: "about" */ '@/views/DownloadPage.vue'),
      meta: {
        title: 'Full Data Download | SubCELL'
      }
    },
  
  







    






  {
    path: '/try-compartment',
    name: 'data-compartment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/TrySublocalization.vue'),
    meta: {
      title: 'Drug Information | SubCELL'
    }
  },







  {
    path: '/data/organism',
    name: 'data-organism',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataOrganism.vue'),
    meta: {
      title: 'Organism Information | SubCELL'
    }
  },



  {
    path: '/data/homo',
    name: 'data-homo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/DataHomo.vue'),
    meta: {
      title: 'Homologous Protein Information | SubCELL'
    }
  },


  {
    path: '/browse-compartment',
    name: 'browse',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BrowseSublocalization.vue'),
    meta: {
      title: 'Browse Compartment | SubCELL'
    }
  },
  {
    path: '/browse-organism',
    name: 'browse',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BrowseOrganism.vue'),
    meta: {
      title: 'Browse Organism | SubCELL'
    }
  },










  // {
  //   path: '/search-disease-page',
  //   name: 'search-disease-page',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDiseasePage.vue'),
  //   meta: {
  //     title: 'Search for Disease | SubCELL'
  //   }
  // },
  // {
  //   path: '/search-drug-page',
  //   name: 'search-drug-page',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDrugPage.vue'),
  //   meta: {
  //     title: 'Search for Drug | SubCELL'
  //   }
  // },



  // {
  //   path: '/search-drug',
  //   name: 'search-drug',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDrug.vue'),
  //   meta: {
  //     title: 'Drug Search Results | SubCELL'
  //   }
  // },
  // {
  //   path: '/search-disease',
  //   name: 'search-disease',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/SearchDisease.vue'),
  //   meta: {
  //     title: 'Disease Search Results | SubCELL'
  //   }
  // },


  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/AboutPage.vue'),
  //   meta: {
  //     title: 'About | SubCELL'
  //   }
  // },

  // {
  //   path: '/browse',
  //   name: 'browse',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/BrowsePage.vue'),
  //   meta: {
  //     title: 'Browse Biomarkers | SubCELL'
  //   }
  // },

  {
    path: '/manual',
    name: 'manual',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ManualPage.vue'),
    meta: {
      title: 'Manual | SubCELL'
    }
  },
  // {
  //   path: '/citation',
  //   name: 'citation',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/CitationPage.vue'),
  //   meta: {
  //     title: 'Citation Guidelines for SubCELL | SubCELL'
  //   }
  // },
  {
    path: '/to-be-available',
    name: 'to-be-available',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ToBeAvailable.vue'),
    meta: {
      title: 'To be available | SubCELL'
    }
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div>
    <div-unit style="margin-top: 50px" unitTitle="Search for DNA">
      <div
        v-if="homeBlock != 'Yes'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for DNAs in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-dna?api=fullText&type=DNA&keyword=CDKN1A"
            hreflang="en"
            class="search-color"
            >CDKN1A</a
          >;
          <a
            href="/search-dna?api=fullText&type=DNA&keyword=MYC"
            hreflang="en"
            class="search-color"
            >MYC</a
          >;
          <a
            href="/search-dna?api=fullText&type=DNA&keyword=EGFR"
            hreflang="en"
            class="search-color"
            >EGFR</a
          >
          ...
        </p>
      </div>

      <!-- <SearchDNAFullText>
        
      </SearchDNAFullText> -->

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for DNAs by Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the DNA name.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="DNAname_check"
            v-model="DNAname"
            filterable
            default-first-option
            placeholder="Please select a DNA name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in DNAnameOptions"
              :key="index"
              :label="item.molecule_name"
              :value="item.molecule_name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="submitSublocalizationDNA()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
      </div>
    </div-unit>
  </div>
</template>
    
<script>
import DivUnit from "@/components/DivUnit.vue";
// import SearchDNAFullText from "@/components/searchBlock/SearchDNAFullText.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
      seq_text: "",

      DNAname: "",
      DNAnameOptions: [],
      DNAname_check: true,
    };
  },
  props: {
    homeBlock: String,
  },
  components: {
    DivUnit,
    // SearchDNAFullText,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-dna`,
        query: {
          api: "fullText",
          type: "DNA",
          keyword: this.fullText,
        },
      });
    },

    submitSublocalizationDNA() {
      this.$router.push({
        path: `/search-dna`,
        query: {
          api: "fullText",
          type: "DNA",
          keyword: this.DNAname,
        },
      });
    },
  },

  beforeMount() {},
  mounted() {
    api.GetDNAname().then((res) => {
      this.DNAname_check = false;
      this.DNAnameOptions = res.data.options;
    });
  },
};
</script>
<style lang="less" scoped>
@color-theme: #63789b;
@bg-theme: #f6f7f9;
@color-theme-hover: #63789b94;

.page-title,
.search-color {
  color: @color-theme;
}

.input_example {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;
}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    
<template>
  <div>
    <div-unit style="margin-top: 50px" unitTitle="Search for Subcellular Compartment">
      <div
        v-if="homeBlock != 'Yes'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for Compartments in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-compartment?api=fullText&type=Compartment&keyword=Mitochondrion"
            hreflang="en"
            class="search-color"
            >Mitochondrion
            </a
          >;
          <a
            href="/search-compartment?api=fullText&type=Compartment&keyword=Nucleus"
            hreflang="en"
            class="search-color"
            >Nucleus</a
          >;
          <a
            href="/search-compartment?api=fullText&type=Compartment&keyword=Endosome"
            hreflang="en"
            class="search-color"
            >Endosome</a
          >
          ...
        </p>
      </div>

      <!-- <SearchCompartmentFullText>
        
      </SearchCompartmentFullText> -->

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for Compartments by Organism & Compartment:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the organism, then a list of compartment under the
            selected organism will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <!-- <el-select
            v-loading="organismCompartment_check"
            v-model="organismCompartment"
            filterable
            default-first-option
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in organismCompartmentOptions"
              :key="index"
              :label="`${item.organism_class}: ${item.organism}`"
              :value="item.organism_id"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            v-loading="organismCompartment_check"
            v-model="organismCompartment"
            :options="organismCompartmentOptions"
            filterable
            default-first-option
            :props="{ expandTrigger: 'hover' }"
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <template #default="{ node, data }">
              <span style="float: left">{{ node.label }}</span>
              <span
                v-if="data.children"
                style="
                  float: right;
                  color: #f56c6c;
                  font-size: 13px;
                  padding-left: 5px;
                "
                >{{ data.children.length }} organisms</span
              >
            </template>
          </el-cascader>

          <el-button
            class="btn-submit"
            @click="resetOrganismCompartment()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
           <el-select
            v-loading="sublocalizationCompartment_check"
            v-model="sublocalizationCompartment"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in sublocalizationCompartmentOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <!-- <el-cascader
            v-loading="sublocalizationCompartment_check"
            v-model="sublocalizationCompartment"
            :options="sublocalizationCompartmentOptions"
            filterable
            default-first-option
            :props="cascaderProps"
            clearable
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
          >
          </el-cascader> -->
          <el-button
            class="btn-submit"
            @click="submitSublocalizationCompartment()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
      </div>

    </div-unit>
  </div>
</template>
    
    <script>
import DivUnit from "@/components/DivUnit.vue";
// import SearchCompartmentFullText from "@/components/searchBlock/SearchCompartmentFullText.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
      seq_text: "",

      organismCompartment: [],
      sublocalizationCompartment: "",
      organismCompartmentOptions: [],
      sublocalizationCompartmentOptions: [],

      cascaderProps: {
        expandTrigger: "hover",
        checkStrictly: true, // 可选，允许选择任意节点
        lazy: true,
        lazyLoad(node, resolve) {
          // Here you can load the children dynamically if needed
          if (node.level === 0) {
            setTimeout(() => {
              resolve([
                
              ]);
            }, 1000);
          } else {
            resolve([]);
          }
        },
        emitPath: false,
      },

      organismCompartment_check: true,
      sublocalizationCompartment_check: false,
    };
  },
  props: {
    homeBlock: String,
  },
  components: {
    DivUnit,
    // SearchCompartmentFullText,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-compartment`,
        query: {
          api: "fullText",
          type: "Compartment",
          keyword: this.fullText,
        },
      });
    },

    resetOrganismCompartment() {
      this.organismCompartment = "";
      this.sublocalizationCompartment = "";
    },
    submitSublocalizationCompartment() {
      this.$router.push({
        path: `/search-compartment`,
        query: {
          api: "organismSublocalization",
          type: "Compartment",
          group: this.organismCompartment[1],
          keyword: this.sublocalizationCompartment,
        },
      });
    },


  },

  beforeMount() {},
  mounted() {
    api
      .postOrganism4Search({
        type: "Compartment",
      })
      .then((res) => {
        this.organismCompartment_check = false;
        this.organismCompartmentOptions = res.data.options;
        // this.organismCompartment = [this.organismCompartmentOptions[0].value]
        this.organismCompartment = [
          res.data.options[0].value,
          res.data.options[0].children[0].value,
        ];
      });
  },
  watch: {
    organismCompartment: {
      handler: function () {
        this.sublocalizationCompartment_check = true;
        this.sublocalizationCompartment = "";
        api
          .postSublocalization4Search({
            data: this.organismCompartment,
            type: "Compartment",
          })
          .then((res) => {
            this.sublocalizationCompartment_check = false;
            this.sublocalizationCompartmentOptions = res.data.options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #cfa158;
@bg-theme: #fcfaf7;
@color-theme-hover: #cfa15894;
a {
  color: @color-theme !important;
}
.input_example {
  color: @color-theme;
}
.page-title,
.search-color {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;
}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    
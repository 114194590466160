<template>
  <div>
    <div-unit style="margin-top: 50px" unitTitle="Search for Protein">
      <div
        v-if="homeBlock != 'Yes'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for Proteins in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-protein?api=fullText&type=Protein&keyword=HSP90AA1"
            hreflang="en"
            class="search-color"
            >HSP90AA1</a
          >;
          <a
            href="/search-protein?api=fullText&type=Protein&keyword=TP53 human"
            hreflang="en"
            class="search-color"
            >TP53 human</a
          >;
          <a
            href="/search-protein?api=fullText&type=Protein&keyword=GLI1"
            hreflang="en"
            class="search-color"
            >GLI1</a
          >;
          <a
            href="/search-protein?api=fullText&type=Protein&keyword=Catenin beta-1"
            hreflang="en"
            class="search-color"
            >Catenin beta-1</a
          >;

          <a
            href="/search-protein?api=fullText&type=Protein&keyword=Mitochondrion "
            hreflang="en"
            class="search-color"
            >Mitochondrion</a
          >
          ...
        </p>
      </div>

      <!-- <SearchProteinFullText>
        
      </SearchProteinFullText> -->

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for Proteins by Organism & Compartment:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the organism, then a list of compartment under the
            selected organism will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <!-- <el-select
            v-loading="organismProtein_check"
            v-model="organismProtein"
            filterable
            default-first-option
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in organismProteinOptions"
              :key="index"
              :label="`${item.organism_class}: ${item.organism}`"
              :value="item.organism_id"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            v-loading="organismProtein_check"
            v-model="organismProtein"
            :options="organismProteinOptions"
            filterable
            default-first-option
            :props="{ expandTrigger: 'hover' }"
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <template #default="{ node, data }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top-start"
              >
                <span style="float: left">
                  {{ node.label }}
                </span>
              </el-tooltip>
              <span
                v-if="data.children"
                style="
                  float: right;
                  color: #f56c6c;
                  font-size: 13px;
                  padding-left: 5px;
                "
                >{{ data.children.length }} organisms</span
              >
            </template>
          </el-cascader>

          <el-button
            class="btn-submit"
            @click="resetOrganismProtein()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <!-- <el-select
            v-loading="sublocalizationProtein_check"
            v-model="sublocalizationProtein"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in sublocalizationProteinOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select> -->

          <!-- {{ sublocalizationProtein }} -->
          <el-cascader
            v-loading="sublocalizationProtein_check"
            v-model="sublocalizationProtein"
            :options="sublocalizationProteinOptions"
            filterable
            default-first-option
            :props="cascaderProps"
            clearable
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
            popper-class="xxs"
          >
            <template #default="{ node }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top-start"
              >
                <span style="float: left">
                  {{ node.label }}
                </span>
              </el-tooltip>
            </template>
          </el-cascader>
          <el-button
            class="btn-submit"
            @click="submitSublocalizationProtein()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for Proteins by Sequence Similarity:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please enter the fasta format of the protein sequence for similarity
            search. (
            <span
              class="input_example"
              @click="fastaExample('protein-example1')"
              ># Example1</span
            >,
            <span
              class="input_example"
              @click="fastaExample('protein-example2')"
            >
              # Example2</span
            >,
            <span
              class="input_example"
              @click="fastaExample('protein-example3')"
              ># Example3</span
            >
            )
          </p>
        </div>

        <div style="margin-left: 94px">
          <el-input
            type="textarea"
            v-model="seq_text"
            rows="6"
            style="width: 1020px; border-radius: 10px"
            class="protein-input"
          ></el-input>
        </div>
        <div
          style="display: flex; justify-content: space-evenly; margin-top: 20px"
        >
          <el-button
            class="btn-submit"
            @click="fastaReset()"
            style="font-size: 22px; width: 120px; border-radius: 10px"
            >Reset</el-button
          >
          <el-button
            class="btn-submit"
            @click="fastaSubmit()"
            style="font-size: 22px; width: 120px; border-radius: 10px"
            >Submit</el-button
          >
        </div>
      </div>
    </div-unit>
  </div>
</template>
    
    <script>
import DivUnit from "@/components/DivUnit.vue";
// import SearchProteinFullText from "@/components/searchBlock/SearchProteinFullText.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
      seq_text: "",

      organismProtein: [],
      sublocalizationProtein: "",
      organismProteinOptions: [],
      sublocalizationProteinOptions: [],

      cascaderProps: {
        expandTrigger: "hover",
        checkStrictly: true, // 可选，允许选择任意节点
        lazy: true,
        lazyLoad(node, resolve) {
          // Here you can load the children dynamically if needed
          if (node.level === 0) {
            setTimeout(() => {
              resolve([]);
            }, 1000);
          } else {
            resolve([]);
          }
        },
        emitPath: false,
      },

      organismProtein_check: true,
      sublocalizationProtein_check: false,
    };
  },
  props: {
    homeBlock: String,
  },
  components: {
    DivUnit,
    // SearchProteinFullText,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-protein`,
        query: {
          api: "fullText",
          type: "Protein",
          keyword: this.fullText,
        },
      });
    },

    resetOrganismProtein() {
      this.organismProtein = "";
      this.sublocalizationProtein = "";
    },
    submitSublocalizationProtein() {
      this.$router.push({
        path: `/search-protein`,
        query: {
          api: "organismSublocalization",
          type: "Protein",
          group: this.organismProtein[1],
          keyword: this.sublocalizationProtein,
        },
      });
    },

    fastaExample(example) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `/example/${example}.fasta`, false);
      xhr.overrideMimeType("text/html;charset=utf-8");
      xhr.send(null);
      this.seq_text = xhr.responseText;
    },
    fastaReset() {
      this.seq_text = "";
    },
    fastaSubmit() {
      var mission_id = "" + new Date().getTime();
      var post_data = {
        seq_text: this.seq_text,
        mission_id: mission_id,
        type: "Protein",
      };
      if (this.seq_text.trim() == "") {
        this.$alert("Please enter the correct protein sequence", "Error", {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Close",
        });
      } else {
        api.postSequence(post_data).then((res) => {
          console.log(res);
        });
        this.$router.push("/search-protein-by-seq?jobID=" + mission_id);
      }
    },
  },

  beforeMount() {},
  mounted() {
    api
      .postOrganism4Search({
        type: "Protein",
      })
      .then((res) => {
        this.organismProtein_check = false;
        this.organismProteinOptions = res.data.options;
        // this.organismProtein = [this.organismProteinOptions[0].value]
        this.organismProtein = [
          res.data.options[0].value,
          res.data.options[0].children[0].value,
        ];
      });
  },
  watch: {
    organismProtein: {
      handler: function () {
        this.sublocalizationProtein_check = true;
        this.sublocalizationProtein = "";
        api
          .postSublocalization4Search({
            data: this.organismProtein,
            type: "Protein",
          })
          .then((res) => {
            this.sublocalizationProtein_check = false;
            this.sublocalizationProteinOptions = res.data.options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #409eff;
@bg-theme: #f5f8fd;
@color-theme-hover: #409eff94;
a {
  color: @color-theme !important;
}
.input_example {
  color: @color-theme;
}
.page-title,
.search-color {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;
}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    
<template>
  <div class="main-width">


    <div class="home-search-tab-part">
      <div v-show="activeName == 'protein'">
        <SearchProteinFullText></SearchProteinFullText>
      </div>

      <div v-show="activeName == 'rna'">
        <SearchRnaFullText></SearchRnaFullText>
      </div>

      <div v-show="activeName == 'dna'">
        <SearchDnaFullText></SearchDnaFullText>
      </div>

      <div v-show="activeName == 'compartment'">
        <SearchCompartmentFullText></SearchCompartmentFullText>
      </div>
      <div
      style="
        display: flex;
        justify-content: space-around;
        width: 85%;
        margin: -25px auto 0 auto;
      "
    >
      <div
        @click="activeName = 'protein'"
        :class="[
          'tag-normal',
          'tag-protein',
          activeName == 'protein' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i class="icon iconfont icon-p2" style="font-size: 37px"></i>
        </div>
        <div class="tag-title">Protein</div>
      </div>
      <div
        @click="activeName = 'rna'"
        :class="[
          'tag-normal',
          'tag-rna',
          activeName == 'rna' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-r3"
            style="transform: scale(1.1)" 
          ></i>
        </div>
        <div class="tag-title">R&nbsp;&nbsp;N&nbsp;&nbsp;A</div>
      </div>
      <div
        @click="activeName = 'dna'"
        :class="[
          'tag-normal',
          'tag-dna',
          activeName == 'dna' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-dna1"
            style="font-size: 37px; transform: scale(1.2)"
          ></i>
        </div>
        <div class="tag-title">D&nbsp;&nbsp;N&nbsp;&nbsp;A</div>
      </div>
      <div
        @click="activeName = 'compartment'"
        :class="[
          'tag-normal',
          'tag-compartment',
          activeName == 'compartment' ? 'active' : 'deactive',
        ]"
      >
        <div class="tag-icon">
          <i
            class="icon iconfont icon-xb2"
            style="font-size: 37px; transform: scale(1.4)"
          ></i>
        </div>
        <div class="tag-title">Compartment</div>
      </div>
    </div>
    </div>

    <div>
      <div v-show="activeName == 'protein'">
        <SearchProteinBlock :homeBlock="'Yes'"></SearchProteinBlock>
      </div>

      <div v-show="activeName == 'rna'">
        <SearchRnaBlock :homeBlock="'Yes'"></SearchRnaBlock>
      </div>

      <div v-show="activeName == 'dna'">
        <SearchDnaBlock :homeBlock="'Yes'"></SearchDnaBlock>
      </div>
      <div v-show="activeName == 'compartment'">
        <SearchCompartmentBlock :homeBlock="'Yes'"></SearchCompartmentBlock>
      </div>
    </div>

    <!-- <div class="bioClass-part">
      <p style="font-weight: bold; margin-bottom: 20px">
        The latest version of TheMarker contained:
      </p>
      <p style="margin-bottom: 20px">
        <img
          :src="`${localUrl}/files/home-picture/bc1.png`"
          style="width: 40px; margin-bottom: -14px"
        />
        218 <i>pharmacodynamic biomarkers</i> indicating the clinical efficacies
        of 1,636 approved, clinical trial, preclinical drugs that cover 115
        molecular classes (such as: AChE inhibitors, MetAP2 inhibitors, and LPA1
        antagonists) for the treatment of 112 disease classes defined by the WHO
        ICD-11 (such as Alzheimer disease, obesity and systemic sclerosis).
      </p>
    </div> -->
  </div>
</template>

<script>
import SearchProteinBlock from "@/components/searchBlock/SearchProteinBlock.vue";
import SearchRnaBlock from "@/components/searchBlock/SearchRnaBlock.vue";
import SearchDnaBlock from "@/components/searchBlock/SearchDnaBlock.vue";
import SearchCompartmentBlock from "@/components/searchBlock/SearchCompartmentBlock.vue";
import SearchDnaFullText from "@/components/searchBlock/SearchDnaFullText.vue";
import SearchRnaFullText from "@/components/searchBlock/SearchRnaFullText.vue";
import SearchProteinFullText from "@/components/searchBlock/SearchProteinFullText.vue";
import SearchCompartmentFullText from "@/components/searchBlock/SearchCompartmentFullText.vue";
// import DivUnit from "@/components/DivUnit.vue";
// import * as echarts from "echarts";
// import api from "../api/index";
import pathUrl from "../api/path";
export default {
  name: "HomeView",
  data() {
    return {
      activeName: "protein",
      localUrl: pathUrl.localUrl,
    };
  },
  components: {
    SearchProteinBlock,
    SearchRnaBlock,
    SearchDnaBlock,
    SearchCompartmentBlock,
    SearchDnaFullText,
    SearchRnaFullText,
    SearchProteinFullText,
    SearchCompartmentFullText,
  },

  methods: {},
  beforeMount() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@color-protein: #409eff;
@color-rna: #60bb64;
@color-dna: #63789b;
@color-compartment: #cfa158;
/deep/.div-title {
  display: none !important;
}
.tag-icon {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
}

.tag-normal:hover {
  .tag-icon {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

.tag-normal {
  display: flex;
  height: 60px;

  cursor: pointer;
  border-radius: 30px;
  .tag-icon {
    margin: auto 0;
    width: 50px;
    height: 50px;
    background: #fafafa;
    border-radius: 25px;
    margin-left: 6px;
    display: flex;
  }
  .tag-icon-hover {
    margin-right: 18px;
    display: flex;
    .fa {
      font-size: 27px;
      margin: auto;
    }
  }
  .tag-title {
    margin: auto;
    font-size: 24px;
    padding-left: 12px;
    padding-right: 50px;
    width: 120px;
    text-align: center;
    font-weight: bold;
    font-style: italic;
  }
  .icon {
    margin: auto;
    font-size: 27px;
  }
}
.tag-protein {
  background: @color-protein;
  border: 2px solid @color-protein;
  color: @color-protein;
  .icon {
    color: @color-protein;
  }
}
.tag-rna {
  background: @color-rna;
  border: 2px solid @color-rna;
  color: @color-rna;
  .icon {
    color: @color-rna;
  }
}

.tag-dna {
  background: @color-dna;
  border: 2px solid @color-dna;
  color: @color-dna;
  .icon {
    color: @color-dna;
  }
}
.tag-compartment {
  background: @color-compartment;
  border: 2px solid @color-compartment;
  color: @color-compartment;
  .icon {
    color: @color-compartment;
  }
}
.deactive {
  background: #fafafa;
}
.active {
  color: #fafafa;
}

/deep/.el-tabs__header {
  top: 271px;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
</style>

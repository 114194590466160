<template>
  <div style="display: flex; flex-direction: column; margin-bottom: 50px">
    <div>
      <p
        style="font-weight: bold; margin-left: 116px; font-size: 22px"
        class="title-color"
      >
        Search for Compartments in Whole Database:
      </p>
    </div>
    <div style="height: 10px"></div>
    <div style="display: flex; margin: 0 auto">
      <el-input
        v-model="fullText"
        placeholder="Enter the keyword"
        style="width: 900px; font-size: 20px"
      >
      </el-input>
      <el-button
        class="btn-submit"
        @click="submitFullText()"
        style="font-size: 22px; width: 120px"
        >Submit</el-button
      >
    </div>
    <p style="margin-left: 116px; font-size: 18px">
      Example:
      <a
        href="/search-compartment?api=fullText&type=Compartment&keyword=Mitochondrion"
        hreflang="en"
        class="search-color"
        >Mitochondrion </a
      >;
      <a
        href="/search-compartment?api=fullText&type=Compartment&keyword=Nucleus"
        hreflang="en"
        class="search-color"
        >Nucleus</a
      >;
      <a
        href="/search-compartment?api=fullText&type=Compartment&keyword=Endosome"
        hreflang="en"
        class="search-color"
        >Endosome</a
      >
      ...
    </p>
  </div>
</template>
    
    <script>
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
    };
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-compartment`,
        query: {
          api: "fullText",
          type: "Compartment",
          keyword: this.fullText,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #cfa158;
@bg-theme: #fcfaf7;
@color-theme-hover: #cfa15894;
a {
  color: @color-theme !important;
}
.input_example {
  color: @color-theme;
}

.title-color,
.page-title,
.search-color {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;
}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    
<template>
  <div style="display: flex; flex-direction: column; margin-bottom: 50px">
    <div>
      <p style="font-weight: bold; margin-left:116px; font-size: 22px" class="title-color">
        Search for Proteins in Whole Database:
      </p>
    </div>
    <div style="height: 10px"></div>
    <div style="display: flex; margin: 0 auto">
      <el-input
        v-model="fullText"
        placeholder="Enter the keyword"
        style="width: 900px; font-size: 20px"
      >
      </el-input>
      <el-button
        class="btn-submit"
        @click="submitFullText()"
        style="font-size: 22px; width: 120px"
        >Submit</el-button
      >
    </div>
    <p style="margin-left: 116px; font-size: 18px">
      Example:
      <a
        href="/search-protein?api=fullText&type=Protein&keyword=HSP90AA1"
        hreflang="en"
        class="search-color"
        >HSP90AA1</a
      >;
      <a
        href="/search-protein?api=fullText&type=Protein&keyword=TP53 human"
        hreflang="en"
        class="search-color"
        >TP53 human</a
      >;
      <a
        href="/search-protein?api=fullText&type=Protein&keyword=GLI1"
        hreflang="en"
        class="search-color"
        >GLI1</a
      >;
      <a
        href="/search-protein?api=fullText&type=Protein&keyword=Catenin beta-1"
        hreflang="en"
        class="search-color"
        >Catenin beta-1</a
      >;
      <a
        href="/search-protein?api=fullText&type=Protein&keyword=Mitochondrion "
        hreflang="en"
        class="search-color"
        >Mitochondrion</a
      >
      ...
    </p>
  </div>
</template>
    
    <script>
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
    };
  },


  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-protein`,
        query: {
          api: "fullText",
          type: "Protein",
          keyword: this.fullText,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #409eff;
@bg-theme: #f5f8fd;
@color-theme-hover: #409eff94;
a{
  color: @color-theme !important;
}
.input_example{
  color: @color-theme ;
}

.title-color,
.page-title,
.search-color {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;

}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    
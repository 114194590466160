var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-width"},[_c('div',{staticClass:"home-search-tab-part"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'protein'),expression:"activeName == 'protein'"}]},[_c('SearchProteinFullText')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'rna'),expression:"activeName == 'rna'"}]},[_c('SearchRnaFullText')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'dna'),expression:"activeName == 'dna'"}]},[_c('SearchDnaFullText')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'compartment'),expression:"activeName == 'compartment'"}]},[_c('SearchCompartmentFullText')],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around","width":"85%","margin":"-25px auto 0 auto"}},[_c('div',{class:[
        'tag-normal',
        'tag-protein',
        _vm.activeName == 'protein' ? 'active' : 'deactive',
      ],on:{"click":function($event){_vm.activeName = 'protein'}}},[_vm._m(0),_c('div',{staticClass:"tag-title"},[_vm._v("Protein")])]),_c('div',{class:[
        'tag-normal',
        'tag-rna',
        _vm.activeName == 'rna' ? 'active' : 'deactive',
      ],on:{"click":function($event){_vm.activeName = 'rna'}}},[_vm._m(1),_c('div',{staticClass:"tag-title"},[_vm._v("R  N  A")])]),_c('div',{class:[
        'tag-normal',
        'tag-dna',
        _vm.activeName == 'dna' ? 'active' : 'deactive',
      ],on:{"click":function($event){_vm.activeName = 'dna'}}},[_vm._m(2),_c('div',{staticClass:"tag-title"},[_vm._v("D  N  A")])]),_c('div',{class:[
        'tag-normal',
        'tag-compartment',
        _vm.activeName == 'compartment' ? 'active' : 'deactive',
      ],on:{"click":function($event){_vm.activeName = 'compartment'}}},[_vm._m(3),_c('div',{staticClass:"tag-title"},[_vm._v("Compartment")])])])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'protein'),expression:"activeName == 'protein'"}]},[_c('SearchProteinBlock',{attrs:{"homeBlock":'Yes'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'rna'),expression:"activeName == 'rna'"}]},[_c('SearchRnaBlock',{attrs:{"homeBlock":'Yes'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'dna'),expression:"activeName == 'dna'"}]},[_c('SearchDnaBlock',{attrs:{"homeBlock":'Yes'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'compartment'),expression:"activeName == 'compartment'"}]},[_c('SearchCompartmentBlock',{attrs:{"homeBlock":'Yes'}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-icon"},[_c('i',{staticClass:"icon iconfont icon-p2",staticStyle:{"font-size":"37px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-icon"},[_c('i',{staticClass:"icon iconfont icon-r3",staticStyle:{"transform":"scale(1.1)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-icon"},[_c('i',{staticClass:"icon iconfont icon-dna1",staticStyle:{"font-size":"37px","transform":"scale(1.2)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-icon"},[_c('i',{staticClass:"icon iconfont icon-xb2",staticStyle:{"font-size":"37px","transform":"scale(1.4)"}})])
}]

export { render, staticRenderFns }
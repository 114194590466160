<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div id="app">
    <vue-particles id="tsparticles" :loaded="particlesLoaded" :options="aaaa" />
    <el-container style="min-height: 100vh; min-width: 1280px">
      <el-backtop></el-backtop>
      <el-header style="height: auto" class="home-gradient">
        <div style="height: 225px" class="title-bg">
          <div
            class="content-width"
            style="width: 1250px; display: flex; justify-content: space-between"
          >
            <div
              class="v-center"
              style="
                height: 225px;
                width: 1250px;
                display: flex;
                margin: 0 auto;
                justify-content: space-between;
                background-image: url(/GeneralStatic/SubCELL.png);
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
              "
            >
              <div
                style="
                  position: absolute;
                  cursor: pointer;
                  top: 26px;
                  height: 34px;
                  width: 218px;
                  left: 240px;
                  text-align: right;
                "
                @click="playAudio1"
                title="Click to Read SubCELL"
              >
                <img
                  src="/GeneralStatic/play.svg"
                  style="display: initial; width: 25px; margin-top: 4px"
                />
                <audio
                  ref="audio"
                  src="/GeneralStatic/SubCELL.mp3"
                  preload="auto"
                ></audio>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 60px" class="content-width">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            "
          >
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#455da500"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search</template
                >
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-protein-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Protein</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-rna-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for RNA</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-dna-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for DNA</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-compartment-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Subcellular Compartment</router-link
                  >
                </el-menu-item>
              </el-submenu>
              <!-- <el-submenu index="2">
                <template slot="title">
                  <i class="fa fa-list" aria-hidden="true"></i>
                  Browse</template
                >
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/browse-compartment"
                    class="no-line"
                    style="color: black"
                  >
                    Browse By Compartment</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/browse-organism"
                    class="no-line"
                    style="color: black"
                  >
                    Browse By Organism</router-link
                  >
                </el-menu-item>
              </el-submenu> -->

              <el-menu-item>
                <router-link to="/download" class="no-line"
                  ><i class="fa fa-cloud-download" aria-hidden="true"></i
                  >Download</router-link
                >
              </el-menu-item>
              <el-menu-item>
                <router-link to="/manual" class="no-line"
                  ><i class="fa fa-file-text-o" aria-hidden="true"></i
                  >Manual</router-link
                >
              </el-menu-item>


              <!-- <el-submenu index="5">
                <template slot="title">
                  <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  Help</template
                >
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/to-be-available"
                    class="no-line"
                    style="color: black"
                  >
                    About</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/to-be-available"
                    class="no-line"
                    style="color: black"
                  >
                    User Manual</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/to-be-available"
                    class="no-line"
                    style="color: black"
                  >
                    Citation</router-link
                  >
                </el-menu-item>
              </el-submenu> -->

              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <!-- <div
          style="display: flex; height: 60px; justify-content: space-between"
          class="content-width"
        >
          <div style="margin: auto 0; padding: 5px 0 0 0">
            <a class="" href="/" style="">
              <img
                :src="`${localUrl}/files/home-picture/TheMarker.svg`"
                height="52px"
                style="display: initial"
              />
            </a>
          </div>
          <div style="display: flex; flex-direction: row-reverse">
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#455da500"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search</template
                >
                <el-menu-item class="submenu-item" index="4-1">
                  <router-link
                    to="/search-drug-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Drug</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item" index="4-2">
                  <router-link
                    to="/search-disease-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Disease</router-link
                  >
                </el-menu-item>
              </el-submenu>
              <el-menu-item>
                <router-link to="/statistics" class="no-line"
                  ><i class="fa fa-pie-chart" aria-hidden="true"></i>Statistics
                </router-link>
              </el-menu-item>
              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div> -->
      </el-header>

      <el-main>
        <router-view />
      </el-main>

      <br />
      <el-footer id="page-foot" style="height: auto" class="home-gradient">
        <div id="page-foot-row" style="height: 266px">
          <el-row class="content-width" style="display: flex; height: 100%">
            <el-col :span="8">
              <h3 class="foot-title"><b>Cite SubCELL</b></h3>
              <div class="foot-content">
                <p>
                  Y. T. Zhang, W. H. Jiang, T. Li, H. W. Xu, Y. M. Zhu, K. R. Fang, X. Y. Ren, S. S. Wang, Y. Z. Chen*, Y. Zhou*, F. Zhu*.
                  SubCELL: the landscape of subcellular compartment-specific molecular interactions.
                  <a href="https://doi.org/10.1093/nar/gkae863" target="_blank"
                    ><b><i>Nucleic Acids Research</i></b></a
                  >. doi: 10.1093/nar/gkae863 (2024).
                  <!-- <a
                    href="https://www.ncbi.nlm.nih.gov/pubmed/37850638"
                    target="_blank"
                    >PMID: 37850638</a
                  >. -->
                </p>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Visitor Map</b></h3>
              <div
                class="foot-content"
                style="text-align: center; padding-top: 18px"
              >
                <iframe
                  style="transform: scale(1.23)"
                  :src="iframe_url"
                  frameborder="0"
                ></iframe>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Correspondence</b></h3>
              <div class="foot-content">
                <p >
                  <b
                    >Prof. Feng Zhu &nbsp;
                    <a
                      href="https://www.idrblab.org/zhufeng.php"
                      target="_blank"
                    >
                      <i class="fa fa-external-link" aria-hidden="true"></i></a
                  ></b>
                </p>
                <p>
                  College of Pharmaceutical Sciences, Zhejiang University,
                  Hangzhou, China.
                </p>


                <p style="margin-top: 15px">
                  <b
                    >Prof. Yuzong Chen &nbsp;
                    <a
                      href="http://bidd.group/index.html#people"
                      target="_blank"
                    >
                      <i class="fa fa-external-link" aria-hidden="true"></i></a
                  ></b>
                </p>
                <p>
                  State Key Lab of Chemical Oncogenomics, Tsinghua University,
                  Beijing, China
                </p>


              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
// import AboutView from "./views/AboutView.vue";
import pathUrl from "./api/path";
export default {
  name: "App",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      isCollapse: false,
      iframe_url: `${pathUrl.localUrl}/files/visitermap`,
      test: ["a", "b"],

      particlesLoaded: false, // 初始化 particlesLoaded 属性

      aaaa: {
        detectRetina: true, // 启用视网膜屏幕检测，以提高高分辨率屏幕上的粒子显示效果。
        fpsLimit: 60, // 限制动画帧率为60帧每秒，避免过高的帧率消耗过多的资源。
        interactivity: {
          detectsOn: "canvas", // 设置交互检测的元素为画布。
          events: {
            onClick: {
              enable: true, // 启用点击事件。
              mode: "push", // 点击时触发“push”模式，生成新粒子。
            },
            onDiv: {
              elementId: "repulse-div", // 指定一个元素ID，当与该元素交互时触发特定模式。
              enable: false, // 禁用该元素的交互。
              mode: "repulse",
            },
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                enable: false,
                force: 60,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 1,
              size: 40,
              speed: 3,
            },
            connect: {
              distance: 80,
              lineLinked: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              lineLinked: {
                opacity: 1,
              },
            },
            push: {
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          lineLinked: {
            blink: false,
            color: "#000",
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0,
            width: 0,
          },
          rotate: {
            value: 0,
            random: true,
            direction: "clockwise",
            animation: {
              enable: true,
              speed: 3,
              sync: false,
            },
          },
          move: {
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
            bounce: false,
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            limit: 0,
            value: 100,
          },

          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 1,
              sync: false,
            },
            random: false,
            value: 0.3,
          },

          shape: {
            options: {
              character: {
                fill: false,
                font: "Verdana",
                style: "",
                value: "*",
                weight: "400",
              },
              image: [
                {
                  src: "/bg-icon/GolgiBody.png",
                  width: 150,
                  height: 150,
                },
                {
                  src: "/bg-icon/Mitochondria.png",
                  width: 50,
                  height: 50,
                },
                {
                  src: "/bg-icon/EndoplasmicReticulum.png",
                  width: 50,
                  height: 50,
                },
                {
                  src: "/bg-icon/Nucleus.png",
                  width: 50,
                  height: 50,
                },
                {
                  src: "/bg-icon/Lysosome.png",
                  width: 50,
                  height: 50,
                },
                {
                  src: "/bg-icon/CellMembrane.png",
                  width: 50,
                  height: 50,
                },

                {
                  src: "/bg-icon/p1.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/p2.png",
                  width: 5,
                  height: 5,
                },
                {
                  src: "/bg-icon/p1.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/p2.png",
                  width: 5,
                  height: 5,
                },
                {
                  src: "/bg-icon/p1.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/p2.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/r3.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/r3.png",
                  width: 5,
                  height: 5,
                },

                {
                  src: "/bg-icon/r3.png",
                  width: 5,
                  height: 5,
                },

                // {
                //   src: "/bg-icon/r4.svg",
                //   width: 5,
                //   height: 5,
                // },
              ],
            },
            type: "image",
            polygon: {
              nb_sides: 5,
            },
            stroke: {
              color: "#000000",
              width: 0,
            },
          },
          size: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              sync: false,
            },
            random: false,
            value: 40,
          },
        },
        polygon: {
          draw: {
            enable: false,
            lineColor: "#ffffff",
            lineWidth: 0.5,
          },
          move: {
            radius: 10,
          },
          scale: 1,
          type: "none",
          url: "",
        },
      },
    };
  },
  methods: {
    // 在适当的时候更新 particlesLoaded 的值
    onParticlesLoaded() {
      this.particlesLoaded = true;
    },
    playAudio1() {
      this.$refs.audio.play();
    },
  },
  components: {
    // AboutView,
  },
};
</script>


<style lang="less">
@purple: #455da500;

#app {
  font-family: Harding, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.title-bg {
  background: linear-gradient(
    to right,
    #ecf4ff 0%,
    #ecf4ff 50%,
    #fdf6ec 50%,
    #fdf6ec 100%
  );
}
#tsparticles {
  // width: 100%;
  // height: 100%;
  /* position: absolute;   */
  position: fixed;

  z-index: -1;
}
.content-width {
  width: 1250px;
  margin: 0px auto !important;
}
.el-menu-item:hover,
.el-submenu:hover {
  outline: 0;
  background-color: #bce9f04f !important;
  border-radius: 10px;
}

p {
  margin: 5px 0;
}

h3 {
  margin: 15px 0;
}

.el-menu--horizontal {
  // 临时
  // display: block !important;
  .el-submenu__title {
    border-radius: 10px;
    font-size: 24px;
    padding-right: 0;
  }
  .el-submenu__title:hover {
    background-color: #bce9f04f !important;
  }
  .el-submenu__icon-arrow {
    margin-top: 6px !important;
  }
  .el-menu-item,
  .el-submenu {
    // border-radius: 10px;
    // font-size: 20px;
    // font-weight: bold;
    // padding: 0px;

    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    // margin: 0 52px 0 0;
  }
  .el-menu {
    .el-menu-item {
      height: 60px !important;
      padding: 10px !important;
    }
  }
}
// .el-menu--popup {

// }

.el-menu--popup {
  background: #fff !important;
  border-radius: 5px !important;
  padding: 5px 0 !important;
  // box-shadow: 0 6px 12px 2px rgb(171 168 168) !important;
  border: 1px dashed #85858552 !important;
  // border-style: dashed;
  box-shadow: 4px 5px 6px 0px #a1a1a1 !important;
}
.el-header,
.el-main,
.el-footer {
  padding: 0 !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

body {
  display: block;
  margin: 0;
  line-height: 1.42857;
  font-size: 20px;
}

.foot-title {
  color: #ffffff;
  border-bottom: 3px solid #fff;
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
}

.foot-content {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
}

.el-row {
  margin: 0 3%;
  margin-bottom: 20px;
}

.el-col {
  padding: 0 20px;
}

.el-menu-item,
.el-submenu {
  i {
    color: #ffffff !important;
    margin-right: 10px;
    font-size: 24px !important;
    margin-bottom: 8px;
  }
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff00 !important;
  color: #ffffff !important;
}
/* .el-menu-item i:hover{
  color: #ffd04b
} */

.no-line {
  text-decoration: none !important;
  padding: 17px;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  a.router-link-exact-active {
    color: #42b983;
  }
}
.el-main {
  overflow: hidden;
}
.el-button,
.fs20,
.el-tag,
.el-message-box__content,
.el-upload-list__item,
.el-notification__content,
.el-select-dropdown__item,
.el-input--suffix .el-input__inner,
.el-input {
  font-size: 20px !important;
}
.el-backtop {
  box-shadow: 0 0 6px 1px #03a9f4 !important;
}
.home-gradient {
  background: #2c3e50;
}
// #page-foot:before {
//   left: 50%;
//   margin-left: -700px;
//   content: "";
//   display: block;
//   position: absolute;
//   //bottom: 0;
//   margin-top: 58px;
//   right: 0;
//   height: 200px;
//   background: #c6cfd7;
//   border-top-left-radius: 10px;
// }
// #page-foot-row:before {
//   border-top-left-radius: 10px;
//   // background: #455da5;
//   left: 50%;
//   margin-left: -650px;
//   content: "";
//   display: block;
//   position: absolute;

//   right: 0;
//   height: 258px;
// }
// .el-popover {
//   border: 1px solid #6e7dd3 !important;
//   /* font-size: 20px; */
//   box-shadow: 0 2px 12px 0 #cfcece;
// }
.el-loading-mask {
  border-radius: 10px;
}

.submenu-item {
  background: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
  font-size: 20px !important;
}
.submenu-item:hover {
  background: #ff584c30 !important;
}
// .el-menu--horizontal .el-menu .el-menu-item
</style>


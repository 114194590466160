<template>
  <div>
    <div-unit style="margin-top: 50px" unitTitle="Search for RNA">
      <div
        v-if="homeBlock != 'Yes'"
        style="display: flex; flex-direction: column; margin-bottom: 50px"
      >
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for RNAs in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-rna?api=fullText&type=RNA&keyword=SRTEZ90"
            hreflang="en"
            class="search-color"
            >SRTEZ90</a
          >;
          <a
            href="/search-rna?api=fullText&type=RNA&keyword=hsa-miR-34a-5p"
            hreflang="en"
            class="search-color"
            >hsa-miR-34a-5p</a
          >;
          <a
            href="/search-rna?api=fullText&type=RNA&keyword=Nucleus"
            hreflang="en"
            class="search-color"
            >Nucleus</a
          >
          ...
        </p>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for RNAs by Organism & Compartment:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the organism, then a list of compartment under the
            selected organism will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <!-- <el-select
            v-loading="organismRNA_check"
            v-model="organismRNA"
            filterable
            default-first-option
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in organismRNAOptions"
              :key="index"
              :label="`${item.organism_class}: ${item.organism}`"
              :value="item.organism_id"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            v-loading="organismRNA_check"
            v-model="organismRNA"
            :options="organismRNAOptions"
            filterable
            default-first-option
            :props="{ expandTrigger: 'hover' }"
            placeholder="Step1: Please select an organism"
            style="width: 900px"
          >
            <template #default="{ node, data }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top-start"
              >
                <span style="float: left">
                  {{ node.label }}
                </span>
              </el-tooltip>
              <span
                v-if="data.children"
                style="
                  float: right;
                  color: #f56c6c;
                  font-size: 13px;
                  padding-left: 5px;
                "
                >{{ data.children.length }} organisms</span
              >
            </template>
          </el-cascader>

          <el-button
            class="btn-submit"
            @click="resetOrganismRNA()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <!-- <el-select
            v-loading="sublocalizationRNA_check"
            v-model="sublocalizationRNA"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in sublocalizationRNAOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select> -->

          <!-- {{ sublocalizationRNA }} -->
          <el-cascader
            v-loading="sublocalizationRNA_check"
            v-model="sublocalizationRNA"
            :options="sublocalizationRNAOptions"
            filterable
            default-first-option
            :props="cascaderProps"
            clearable
            placeholder="Step2: Please select a compartment"
            style="width: 900px"
            popper-class="xxs"
          >
          <template #default="{ node }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top-start"
              >
                <span style="float: left">
                  {{ node.label }}
                </span>
              </el-tooltip>
            </template>
          </el-cascader>
          <el-button
            class="btn-submit"
            @click="submitSublocalizationRNA()"
            style="font-size: 22px; width: 120px"
            >Submit</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for RNAs by Sequence Similarity:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please enter the fasta format of the RNA sequence for similarity
            search. (
            <span
              class="input_example"
              @click="fastaExample('rna-example1')"
              ># Example1</span
            >,
            <span
              class="input_example"
              @click="fastaExample('rna-example2')"
            >
              # Example2</span
            >,
            <span
              class="input_example"
              @click="fastaExample('rna-example3')"
              ># Example3</span
            >
            )
          </p>
        </div>

        <div style="margin-left: 94px">
          <el-input
            type="textarea"
            v-model="seq_text"
            rows="6"
            style="width: 1020px; border-radius: 10px"
            class="protein-input"
          ></el-input>
        </div>
        <div
          style="display: flex; justify-content: space-evenly; margin-top: 20px"
        >
          <el-button
            class="btn-submit"
            @click="fastaReset()"
            style="font-size: 22px; width: 120px; border-radius: 10px"
            >Reset</el-button
          >
          <el-button
            class="btn-submit"
            @click="fastaSubmit()"
            style="font-size: 22px; width: 120px; border-radius: 10px"
            >Submit</el-button
          >
        </div>
      </div>
    </div-unit>
  </div>
</template>
    
    <script>
import DivUnit from "@/components/DivUnit.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",
      seq_text: "",

      organismRNA: [],
      sublocalizationRNA: "",
      organismRNAOptions: [],
      sublocalizationRNAOptions: [],

      cascaderProps: {
        expandTrigger: "hover",
        checkStrictly: true, // 可选，允许选择任意节点
        lazy: true,
        lazyLoad(node, resolve) {
          // Here you can load the children dynamically if needed
          if (node.level === 0) {
            setTimeout(() => {
              resolve([]);
            }, 1000);
          } else {
            resolve([]);
          }
        },
        emitPath: false,
      },

      organismRNA_check: true,
      sublocalizationRNA_check: false,
    };
  },
  props: {
    homeBlock: String,
  },
  components: {
    DivUnit,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-rna`,
        query: {
          api: "fullText",
          type: "RNA",
          keyword: this.fullText,
        },
      });
    },

    resetOrganismRNA() {
      this.organismRNA = "";
      this.sublocalizationRNA = "";
    },
    submitSublocalizationRNA() {
      this.$router.push({
        path: `/search-rna`,
        query: {
          api: "organismSublocalization",
          type: "RNA",
          group: this.organismRNA[1],
          keyword: this.sublocalizationRNA,
        },
      });
    },

    fastaExample(example) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `/example/${example}.fasta`, false);
      xhr.overrideMimeType("text/html;charset=utf-8");
      xhr.send(null);
      this.seq_text = xhr.responseText;
    },
    fastaReset() {
      this.seq_text = "";
    },
    fastaSubmit() {
      var mission_id = "" + new Date().getTime();
      var post_data = {
        seq_text: this.seq_text,
        mission_id: mission_id,
        type: "RNA",
      };
      if (this.seq_text.trim() == "") {
        this.$alert("Please enter the correct rna sequence", "Error", {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Close",
        });
      } else {
        api.postSequence(post_data).then((res) => {
          console.log(res);
        });
        this.$router.push("/search-rna-by-seq?jobID=" + mission_id);
      }
    },
  },

  beforeMount() {},
  mounted() {
    api
      .postOrganism4Search({
        type: "RNA",
      })
      .then((res) => {
        this.organismRNA_check = false;
        this.organismRNAOptions = res.data.options;
        this.organismRNA = [
          res.data.options[0].value,
          res.data.options[0].children[0].value,
        ];
      });
  },
  watch: {
    organismRNA: {
      handler: function () {
        this.sublocalizationRNA_check = true;
        this.sublocalizationRNA = "";
        api
          .postSublocalization4Search({
            data: this.organismRNA,
            type: "RNA",
          })
          .then((res) => {
            this.sublocalizationRNA_check = false;
            this.sublocalizationRNAOptions = res.data.options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #60bb64;
@bg-theme: #f9fdf9;
@color-theme-hover: #60bb6494;

.page-title,
.search-color {
  color: @color-theme;
}

.input_example {
  color: @color-theme;
}
/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme!important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px;
  background: @color-theme;
}

/deep/.el-button:hover,
.el-button:focus {
  background: @color-theme-hover;
}

/deep/.div-title {
  background: @color-theme;
}
/deep/.div-content {
  border-top: 0;
}
/deep/.div-unit {
  border: 3px solid @color-theme;
  border-color: @color-theme;
  background-color: @bg-theme;
}
/deep/.el-cascader__dropdown {
  width: 900px !important;
}
</style>
    